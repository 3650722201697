'use client'
import React from 'react';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import useDeviceType from '@/hook/useDeviceType';
import { v4 as uuidv4 } from 'uuid';
const HeroSection = ({ data }) => {
    const deviceType = useDeviceType()
    return (
        <>
            <div className='h-1  border-gray-300  shadow-mmd'>
            </div>
            <Swiper
                modules={[Scrollbar, A11y, Autoplay, Pagination]} // Include Autoplay module here
                spaceBetween={50}
                slidesPerView={1}
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                autoplay={{ // Add autoplay configuration
                    delay: 6000, // Delay in milliseconds (3 seconds in this case)
                    disableOnInteraction: false, // Keep autoplay active after user interactions
                }}
            // onSlideChange={() => console.log('slide change')}
            >
                {
                    data?.slider_images?.map(brand => {
                        const url = deviceType == 'mobile' ? brand.mob_image_url : brand.image_url
                        return (
                            <SwiperSlide key={uuidv4()}>
                                <Link href={brand.redirect_url} target='_blank'>
                                    <img src={url} loading='lazy' className='w-full ' />
                                </Link>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </>
    );
};

export default HeroSection;
